import { ExtendableError } from './ExtendableError';

export type GatweayErrorCode =
  | 'INSUFFICIENT_FUNDS'
  | 'FRAUD'
  | 'PAYMENT_METHOD_EXPIRED'
  | 'PAYMENT_METHOD_INVALID'
  | 'PAYPAL_BILLING_AGREEMENT_CANCELLED'
  | '3DS_FAILED'
  | 'GENERIC'
  | 'UNKNOWN';

export class GatewayError extends ExtendableError {
  code: GatweayErrorCode;
  retryable: boolean;

  constructor(message: string, code: GatweayErrorCode, retryable = false, errorOptions?: ErrorOptions) {
    super(message, errorOptions);
    this.code = code;
    this.retryable = retryable;
    this.name = 'GatewayError';
  }
}

export const isGatewayError = (error: unknown): error is GatewayError => {
  return error instanceof GatewayError;
};

export type SupaApiErrorCode = 'RENEW_LIMIT_REACHED';

export class SupaApiError extends ExtendableError {
  code: SupaApiErrorCode;

  constructor(message: string, code: SupaApiErrorCode, errorOptions?: ErrorOptions) {
    super(message, errorOptions);
    this.code = code;
    this.name = 'SupaApiError';
  }
}

export const isSupaApiError = (error: unknown): error is SupaApiError => {
  return error instanceof SupaApiError;
};

export interface ApiPaymentGatewayFailedResponse {
  __typename: 'ApiPaymentGatewayFailedResponse';
  errorGroup: GatweayErrorCode;
  isRetryable: boolean;
  message: string;
}
