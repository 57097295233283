/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { useEffect } from 'react';

import type { AnalyticsSnippet, Callback, MiddlewareFunction } from '@segment/analytics-next';

import { GetSignupResponseAccountStatusEnum, GetSignupResponseRingSerialStatusEnum } from 'oura-account-api-client';

import { BillingPeriod, PaymentMethodType, Problem } from '../types';
import { isStrictNever } from './isStrictNever';
import { logger } from './logger';
declare global {
  interface Window {
    // Invoked is added by consent-manager.
    // Since we install analytics via script in HTML, analytics should always defined.
    analytics?: AnalyticsSnippet & { invoked?: boolean };
  }
}

const getLocalStorageHipaaInfo = (): boolean => {
  try {
    const userTraits = JSON.parse(window.localStorage.getItem('ajs_user_traits') ?? '{"isHipaa": false }');
    return !!userTraits.isHipaa;
  } catch (_e) {
    return false;
  }
};

const extendTraits = (traits?: Traits) => {
  const isHipaa = traits?.isHipaa ?? getLocalStorageHipaaInfo();
  return { ...traits, isHipaa };
};

const extendPropertiesByTraits = (
  properties: Record<string, unknown> | undefined,
  traits: Traits,
  customIDs: Record<string, unknown>,
) => {
  return {
    ...properties,
    ...traits,
    ...customIDs,
  };
};

// This hack fixes a bug in Segment Amplitude integration.
// https://jouzen.atlassian.net/browse/MMBER-4110
const fixAmplitudeIntegrationBug: MiddlewareFunction = ({ payload, next }) => {
  const { integrations } = payload.obj;
  if (integrations) {
    const amplitudeEnabled = Boolean(integrations['Actions Amplitude']);
    if (amplitudeEnabled) {
      integrations['Amplitude (Actions)'] = true;
    }
  }
  next(payload);
};

const useInitAmplitudeHack = () => {
  useEffect(() => {
    window.analytics?.addSourceMiddleware(fixAmplitudeIntegrationBug);
  }, []);
};

const useTrackPageLoad = (category: string) =>
  useEffect(() => {
    let previousPath: string | undefined = undefined;
    const customIDs = {
      statsigStableId: getStatsigStableId(),
    };
    const observer = new MutationObserver(() => {
      const currentPath = location.pathname;
      if (currentPath !== previousPath) {
        const referrer = previousPath ?? document.referrer;
        previousPath = currentPath;
        const traits = extendTraits();
        window.analytics?.page(
          category,
          undefined,
          extendPropertiesByTraits({ referrer, category }, traits, customIDs),
          { traits },
          trackingCallback,
        );
      }
    });
    observer.observe(document, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, [category]);

export const useInitAnalytics = (category: string) => {
  useInitAmplitudeHack();
  useTrackPageLoad(category);
};

// https://docs.google.com/spreadsheets/d/1WLX-BCIdwwX_hAV5Rkz-aFyaCRTrsEOJ1I163B0gA8M/edit#gid=63578655
type UIEvent =
  | {
      event: 'CTA Clicked';
      payload:
        | {
            cta: 'login';
            action: 'login';
          }
        | {
            cta: 'get_started';
            action: 'start_membership_onboarding';
          }
        | {
            cta: 'next';
            action: 'onboarding_next_step' | 'renew_next_step' | 'update_pm_next_step' | 'add_payment_method_next_step';
            step?: 'review_payment' | 'billing_address' | 'plan_select' | 'payment_method';
          }
        | {
            cta: 'save_exit';
            action: 'onboarding_next_step';
          }
        | {
            cta: 'save';
            action: 'payment_method_updated';
          }
        | {
            cta: 'update';
            action: 'update_plan';
          }
        | {
            cta: 'cancel_my_membership';
            action: 'cancel_membership';
          }
        | {
            cta: 'keep_my_membership';
            action: 'cancel_membership';
          }
        | {
            cta: 'submit';
            action: 'renew_membership';
          };
    }
  | {
      event: 'Modal Opened';
      payload: {
        cta: `${'paypal' | 'apple' | 'google'}_checkout`;
        action: 'payment_method_added';
        location: 'membership_onboarding';
      };
    }
  | {
      event: 'Link Clicked';
      payload: {
        cta:
          | 'feature_highlights'
          | 'member_experience'
          | 'update_plan'
          | 'view_plan'
          | 'edit_payment'
          | 'view_payment'
          | 'billing_history'
          | 'edit_account'
          | 'cancel_membership'
          | 'renew_membership'
          | 'upgrade_to_gen3'
          | 'signup_planselection_help'
          | string;
        location: 'card' | 'body' | 'footer';
        prepaidMonths?: number;
      };
    };

type FunnelEvent =
  | {
      event: 'Signup Token Valid';
      payload: {
        accountStatus: GetSignupResponseAccountStatusEnum;
        ringSerialStatus: GetSignupResponseRingSerialStatusEnum;
      };
    }
  | {
      event: 'Account Created';
      payload?: never;
    }
  | {
      event:
        | 'Paying Customer Found'
        | 'Entitlement Customer Found'
        | 'Legacy Customer Found'
        | 'Partnership Customer Found';
      payload: {
        existingSubscription: boolean;
        prepaidMonths: number;
      };
    }
  | {
      event: 'Entitlement Subscription Created' | 'Partnership Subscription Created';
      payload?: never;
    }
  | {
      event: 'Address Added';
      payload?: never;
    }
  | {
      event: 'Membership Plan Selected';
      payload: {
        productType: BillingPeriod;
      };
    }
  | {
      event: 'Membership Plan Selected(A)';
      payload: {
        productType: BillingPeriod;
      };
    }
  | {
      event: 'Membership Plan Selected(B)';
      payload: {
        productType: BillingPeriod;
      };
    }
  | {
      event: 'Membership Plan Updated';
      payload: {
        productType: BillingPeriod;
      };
    }
  | {
      event: 'Subscription Created';
      payload: {
        paymentMethodType: TrackingPaymentMethodTypes;
        cta: 'save_exit / checkout';
        action: 'onboarding_complete';
        currency: string;
        locale: string;
        paymentGateway: string;
        billingPeriod: string;
      };
    }
  | {
      event: 'Subscription Renewed';
      payload: {
        paymentMethodType: TrackingPaymentMethodTypes;
        currency: string;
        locale: string;
        paymentGateway: string;
        billingPeriod: string;
      };
    }
  | {
      event: 'Payment Method Updated';
      payload: {
        paymentMethodType: TrackingPaymentMethodTypes;
        currency: string;
        locale: string;
        paymentGateway: string;
        billingPeriod: string;
      };
    }
  | {
      event: 'Signup Funnel Completed';
      payload?: never;
    }
  | {
      event: 'Address Validation Failed';
      payload: {
        reason: string;
        problems: Omit<Problem, 'correctedValue'>[];
        country: string;
      };
    }
  | {
      event: 'Adding Payment Method Failed';
      payload: {
        paymentMethodType: TrackingPaymentMethodTypes;
        reason: string;
      };
    }
  | {
      event: 'Updating Payment Method Failed';
      payload: {
        paymentMethodType: TrackingPaymentMethodTypes;
        reason: string;
      };
    }
  | {
      event: 'Login Failed';
      payload: {
        reason: string;
      };
    }
  | {
      event: 'Update Payment Method Success';
      payload: {
        flow: string;
      };
    };

type Traits = Partial<{
  email: string;
  prepaidMonths: number;
  isHipaa: boolean;
}>;

type TrackEvent = (FunnelEvent | UIEvent) & {
  traits?: Traits;
};

const trackingCallback: Callback = ({ event, stats, attempts }) => {
  const { traits, ...cleanEvent } = event;
  logger.debug(`${event.type} tracking sent to Segment`, {
    event: cleanEvent,
    stats,
    attempts,
  });
};

export const track = ({ event, payload, traits: eventTraits }: TrackEvent) => {
  const traits = extendTraits(eventTraits);
  const customIDs = {
    statsigStableId: getStatsigStableId(),
  };
  window.analytics?.track(
    event,
    {
      ...extendPropertiesByTraits(payload, traits, customIDs),
      statsigEnvironment: {
        tier: import.meta.env.MODE,
      },
    },
    { traits },
    trackingCallback,
  );
};

interface IdentifyEvent {
  traits: Traits;
}

export const setUserId = (userId: string | null) => {
  if (userId) {
    localStorage.setItem('ajs_user_id', `"${userId}"`);
  }
};
const getStatsigStableId = (): string | null => {
  const matchingKey = Array.from({ length: localStorage.length }, (_, i) => localStorage.key(i)).find((key) =>
    key?.startsWith('statsig.stable_id'),
  );
  const statsigStableId = matchingKey && localStorage.getItem(matchingKey);

  return statsigStableId ? statsigStableId?.replace(/"/g, '') : null;
};
export const getLocalStorageUserId = () => {
  const userId = window.localStorage.getItem('ajs_user_id');
  return userId ? userId.replace(/"/g, '') : undefined;
};

export const getLocalStorageAnonymousId = () => {
  const anonymousId = window.localStorage.getItem('ajs_anonymous_id');
  return anonymousId ? anonymousId.replace(/"/g, '') : undefined;
};

export const identify = ({ traits: eventTraits }: IdentifyEvent) => {
  const traits = extendTraits(eventTraits);
  window.analytics?.identify(undefined, traits, { traits }, trackingCallback);
};

type TrackingPaymentMethodTypes = 'Paypal' | 'Credit card';

export const convertPaymentMethodTypeToTrackingType = (type: PaymentMethodType): TrackingPaymentMethodTypes => {
  switch (type) {
    case PaymentMethodType.CreditCard:
      return 'Credit card';
    case PaymentMethodType.PayPal:
      return 'Paypal';
    default: {
      isStrictNever(type);
    }
  }
};
