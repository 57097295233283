import styled from 'styled-components';

import { BackButton } from '../BackButton';
import { useIsDesktopView } from '../useIsDesktopView';
import { AppType } from './BaseHeader';
import { OuraHeaderLogo } from './OuraHeaderLogo';

const Container = styled.header`
  padding-top: 24px;
  padding-bottom: 38px;
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

interface HeaderProps {
  appType: AppType;
  onBackClick?: () => void;
}

export const Header = ({ appType, onBackClick }: HeaderProps) => {
  const [isDesktopView] = useIsDesktopView();

  const logoSize = isDesktopView ? { height: '29px', width: '93px' } : { height: '19px', width: '60px' };

  return (
    <Container>
      <OuraHeaderLogo {...logoSize} app={appType} />
      {onBackClick && <BackButton onClick={onBackClick} />}
    </Container>
  );
};
