import { useEffect } from 'react';
import { useNavigate } from 'react-router';

import { useAuth } from 'apps-common/context/auth';
import { useVerifyNewEmail } from 'apps-common/hooks/AccountApi';
import { useQueryParams } from 'apps-common/hooks/useQueryParams';
import { AccountApiError } from 'apps-common/utils/AccountApiError';
import { throwError } from 'apps-common/utils/errorHandler';
import { Loader } from 'ui';

import { routes } from '../routes';

export const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const query = useQueryParams();
  const token = query.get('token');
  const { logout, loggedIn } = useAuth();
  if (!token) {
    throwError('missingTokenOnEmailVerification');
  }

  const { data, error } = useVerifyNewEmail(token);
  useEffect(() => {
    if (!error && data?.accountId) {
      if (loggedIn) {
        void logout();
      }
      navigate(routes.login);
    }
  }, [data, navigate, logout, loggedIn, error]);

  if (error) {
    if (error instanceof AccountApiError && error.code === 'OneTimeTokenUsed') {
      return throwError('verifyEmailLinkExpired', error);
    }
    return throwError('hubGeneralError', error);
  }
  return <Loader text="We are verifying your email" />;
};
