import { useNavigate } from 'react-router';

import { useGetAccountInfo } from 'apps-common/hooks/AccountApi';
import { t } from 'translations';
import { HorizontalLine, Loader } from 'ui';
import { Header } from 'ui/components/Header/SimpleHeader';
import { MainContainer } from 'ui/styles/containers';
import { PageSubtitle, PageTitle } from 'ui/styles/text';

import { UpdateEmailForm } from '../../components/AccountManagement/UpdateEmailForm';
import { UpdatePasswordForm } from '../../components/AccountManagement/UpdatePasswordForm';
import { routes } from '../../routes';
export const AccountManagePage = () => {
  const navigate = useNavigate();
  const { data, isFetching: isFetchingAccountInfo } = useGetAccountInfo();

  if (isFetchingAccountInfo) {
    return <Loader />;
  }
  const SUB_TITLE =
    data?.accountSource === 'legacy'
      ? t('membership_hub_update_account_information_text')
      : t('membership_hub_update_account_information_text_moi_user');

  return (
    <>
      <Header appType="hub" onBackClick={() => navigate(routes.index)} />
      <PageTitle>{t('membership_hub_account_information')}</PageTitle>
      <PageSubtitle data-testid="pageSubtitle">{SUB_TITLE}</PageSubtitle>
      <MainContainer $padding="10px 5px 0 5px">
        {data?.accountSource === 'legacy' && (
          <>
            <UpdatePasswordForm /> <HorizontalLine $margin="30px 0 40px 0" />
          </>
        )}

        <UpdateEmailForm accountSource={data?.accountSource} />
      </MainContainer>
    </>
  );
};
