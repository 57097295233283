import { css, styled } from 'styled-components';

import { media } from '../themes/signup';

export interface RowContainerProps {
  readonly $border?: boolean;
  readonly $padding?: string;
  readonly $height?: string;
}

export const FooterContainer = styled.footer`
  margin-bottom: 20px;
  text-align: center;
  margin-top: auto;
  display: flex;
  flex-direction: column;

  ${media.small`
    padding-top: 60px;
  `}
`;

export const Container = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    height: 100vh;
    background:
        linear-gradient(180deg, rgba(${theme.colors.backgroundRgbValues}, 0.15) 0%, ${theme.colors.background} 100%),
        linear-gradient(234deg, ${theme.colors.backgroundGradientBlue} 20%, ${theme.colors.background} 100%)
      ;
    background-size: auto 500px;
    background-repeat: no-repeat;
  `,
);

interface ContentContainerProps {
  $alignItems?: string;
  $margin?: string;
  $maxWidth?: string;
  $padding?: string;
  $height?: string;
}

// MainContainer is the main part of HTML. DON'T USE it other than creating a main section of the page. We only use 1 main section per page.
export const MainContainer = styled.main<ContentContainerProps>(
  ({ $alignItems = 'center', $padding = '0', $maxWidth = '100%' }) => css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: ${$alignItems};
    padding: ${$padding};
    max-width: ${$maxWidth};
    margin: 0;
    z-index: 1;
  `,
);

export const RowContainer = styled.div<RowContainerProps>(
  ({ theme, $border, $padding = '20px', $height }) => `
  padding: ${$padding};
  ${$border ? `border-bottom: 1px solid ${theme.colors.grayMedium};` : ''}
  ${$height ? `height: ${$height};` : ''}
`,
);
